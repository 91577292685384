import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function ZendeskMacroGuide() {
  return (
    <Layout
      keywords="Macro recommendation in Zendesk"
      title="Macro Recommendation using AI"
      description="Documentation on how IrisAgent AI recommends the best macro to apply to a ticket. Automate Zendesk macros with AI."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/macro-recommendation-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Macro Recommendation and Automation</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Macro Automation</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>Macro Recommendation Guide for Zendesk</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    <a href="https://support.zendesk.com/hc/en-us/articles/4408844187034-Creating-macros-for-repetitive-ticket-responses-and-actions" target="_blank">Macros in Zendesk</a> are a great way to help agents address repetitive customer queries and automate workflows.  However, as support operations scale and complexity increases, the number of macros also grows. IrisAgent has worked with companies that have large support operations and understand the frustration this causes for agents, namely,<br/><br/>
                    <ul>
                        <li>Which macro to select? Wrong selections lead to incorrect troubleshooting, longer handle times and poort customer service.</li>
                        <li>Newer agents find navigating macros very difficult.</li>
                        <li>Hard to train on all macros and select the right one in real-time.</li>
                    </ul>
                    <br/>
                    IrisAgent has solved this with AI - IrisAgent AI powered macro auto-selection help agents pick the right macro real-time with very high accuracy. Check out the screenshot below to see how IrisAgent recommends the right macro to apply to a ticket within Zendesk.<br/>
                    <img
                        src="/img/macro_recommendation.png"
                        style={{
                          width: "80%",
                          padding: "5%",
                        }}
                        alt="Macro Recommendation"
                    />
                    <br/>
                    <br/>
                    <h2>
                    Prerequisites
                    </h2>
                    <br/>
                    <h3>
                    Step 1: Macro settings in Zendesk
                    </h3>
                    <ol>
                    <li>
                    Ensure that each relevant macro has an action to add tags to the ticket. This is important for IrisAgent to understand the context of the ticket and recommend the right macro.
                     <img
                        src="/img/macro_add_tag.png"
                        style={{
                          width: "80%",
                          padding: "5%",
                        }}
                        alt="Macro Add Tag"
                    />
                    </li>
                    <li>
                    The tag mentioned above should be unique per macro.
                    </li>
                    </ol>

                    <br/>
                    <h3>
                    Step 2: Notify IrisAgent
                    </h3>
                    <br/>
                    Let <a href="mailto:contact@irisagent.com" target="_blank">IrisAgent know</a> that you would like to activate macro recommendation in Zendesk. If there are certain macros, that you'd like to exclude from the recommendation, please let us know.
                    <br/>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}
